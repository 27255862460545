/**
 * SclCarouselCard
 */

// template
const template = `
	<div>

		<!-- grid to contain carousel -->
		<v-container>
			<v-layout row wrap>

				<v-flex xs12 class="scl-carousel-card__title-container">
					<h2 class="scl-carousel-card__title">popular equipment</h2>
					<a v-if="$vuetify.breakpoint.mdAndUp" class="scl-carousel-card__cta" href="#">view all</a>
				</v-flex>

				<v-flex xs12>

					<!-- vuetify carousel wrapper -->
					<v-carousel class="scl-carousel-card__carousel" :hide-delimiters="false" interval="7000" :cycle="false">

						<!-- mobile carousel -->
						<v-carousel-item v-if="$vuetify.breakpoint.xsOnly">
							<v-container grid-list-xl class="px-0">
								<v-layout>
									<v-flex xs12>
										<scl-card :api="{ 'description': true }" class="scl-component scl-card"></scl-card>
									</v-flex>
								</v-layout>
							</v-container>
						</v-carousel-item>
						<v-carousel-item v-if="$vuetify.breakpoint.xsOnly">
							<v-container grid-list-xl class="px-0">
								<v-layout>
									<v-flex xs12>
										<scl-card :api="{ 'description': true }" class="scl-component scl-card"></scl-card>
									</v-flex>
								</v-layout>
							</v-container>
						</v-carousel-item>

						<!-- tablet carousel -->
						<v-carousel-item v-if="$vuetify.breakpoint.smOnly">
							<v-container grid-list-xl class="px-0">
								<v-layout>
									<v-flex sm6>
										<scl-card :api="{ 'description': true }" class="scl-component scl-card"></scl-card>
									</v-flex>
									<v-flex sm6>
										<scl-card :api="{ 'description': true }" class="scl-component scl-card"></scl-card>
									</v-flex>
								</v-layout>
							</v-container>
						</v-carousel-item>
						<v-carousel-item v-if="$vuetify.breakpoint.smOnly">
							<v-container grid-list-xl class="px-0">
								<v-layout>
									<v-flex sm6>
										<scl-card :api="{ 'description': true }" class="scl-component scl-card"></scl-card>
									</v-flex>
									<v-flex sm6>
										<scl-card :api="{ 'description': true }" class="scl-component scl-card"></scl-card>
									</v-flex>
								</v-layout>
							</v-container>
						</v-carousel-item>

						<!-- desktop small carousel -->
						<v-carousel-item v-if="$vuetify.breakpoint.mdOnly">
							<v-container grid-list-xl class="px-0">
								<v-layout>
									<v-flex md4>
										<scl-card :api="{ 'description': true }" class="scl-component scl-card"></scl-card>
									</v-flex>
									<v-flex md4>
										<scl-card :api="{ 'description': true }" class="scl-component scl-card"></scl-card>
									</v-flex>
									<v-flex md4>
										<scl-card :api="{ 'description': true }" class="scl-component scl-card"></scl-card>
									</v-flex>
								</v-layout>
							</v-container>
						</v-carousel-item>
						<v-carousel-item v-if="$vuetify.breakpoint.mdOnly">
							<v-container grid-list-xl class="px-0">
								<v-layout>
									<v-flex md4>
										<scl-card :api="{ 'description': true }" class="scl-component scl-card"></scl-card>
									</v-flex>
									<v-flex md4>
										<scl-card :api="{ 'description': true }" class="scl-component scl-card"></scl-card>
									</v-flex>
									<v-flex md4>
										<scl-card :api="{ 'description': true }" class="scl-component scl-card"></scl-card>
									</v-flex>
								</v-layout>
							</v-container>
						</v-carousel-item>

						<!-- desktop large carousel -->
						<v-carousel-item v-if="$vuetify.breakpoint.lgAndUp">
							<v-container grid-list-xl class="px-0">
								<v-layout>
									<v-flex lg3>
										<scl-card :api="{ 'description': true }" class="scl-component scl-card"></scl-card>
									</v-flex>
									<v-flex lg3>
										<scl-card :api="{ 'description': true }" class="scl-component scl-card"></scl-card>
									</v-flex>
									<v-flex lg3>
										<scl-card :api="{ 'description': true }" class="scl-component scl-card"></scl-card>
									</v-flex>
									<v-flex lg3>
										<scl-card :api="{ 'description': true }" class="scl-component scl-card"></scl-card>
									</v-flex>
								</v-layout>
							</v-container>
						</v-carousel-item>
						<v-carousel-item v-if="$vuetify.breakpoint.lgAndUp">
							<v-container grid-list-xl class="px-0">
								<v-layout>
									<v-flex lg3>
										<scl-card :api="{ 'description': true }" class="scl-component scl-card"></scl-card>
									</v-flex>
									<v-flex lg3>
										<scl-card :api="{ 'description': true }" class="scl-component scl-card"></scl-card>
									</v-flex>
									<v-flex lg3>
										<scl-card :api="{ 'description': true }" class="scl-component scl-card"></scl-card>
									</v-flex>
									<v-flex lg3>
										<scl-card :api="{ 'description': true }" class="scl-component scl-card"></scl-card>
									</v-flex>
								</v-layout>
							</v-container>
						</v-carousel-item>

					</v-carousel>

				</v-flex>

				<v-flex v-if="$vuetify.breakpoint.smAndDown" xs12 class="scl-carousel-card__title-container">
					<a class="scl-carousel-card__cta" href="#">view all</a>
				</v-flex>

			</v-layout>
		</v-container>

	</div>
`;

// export template
export default template;
