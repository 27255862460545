/**
 * SclCarouselTeam
 */

// template
const template = `
	<div>

		<!-- grid to contain carousel -->
		<v-container>
			<v-layout row wrap>
				<v-flex xs12>

					<!-- vuetify carousel wrapper -->
					<v-carousel class="scl-carousel-team__carousel" hide-controls interval="7000" :cycle="false">

						<!-- mobile carousel -->
						<v-carousel-item v-if="$vuetify.breakpoint.xsOnly">
							<v-container grid-list-xl class="px-0 pb-0">
								<v-layout>
                                    <v-flex xs12>
                                    <v-card flat class="scl-card__card">
                                    <v-img
                                        :src="$root.dummyImage43" class="scl-card__image"
                                        aspect-ratio="1"
                                        max-height="240"
																				max-width="240"
                                    >
                                    </v-img>

                                    <div class="scl-card__content">
                                        <h5 class="scl-card__title">Kate Best</h5>
                                        <p class="scl-card__description">Executive Digital Producer</p>
                                        <p class="scl-card__description">Sydney simon</p>
                                    </div>
                                </v-card>
                                    </v-flex>
								</v-layout>
							</v-container>
						</v-carousel-item>
						<v-carousel-item v-if="$vuetify.breakpoint.xsOnly">
							<v-container grid-list-xl class="px-0 pb-0">
								<v-layout>
									<v-flex xs12>
                                        <v-card flat class="scl-card__card">
                                            <v-img
                                                :src="$root.dummyImage43" class="scl-card__image"
                                                :aspect-ratio="$vuetify.breakpoint.smAndUp? '1': '1'"
                                                max-height="240"
                                            >
                                            </v-img>

                                            <div class="scl-card__content">
                                                <h5 class="scl-card__title">Kate Best</h5>
                                                <p class="scl-card__description">Executive Digital Producer</p>
                                                <p class="scl-card__description">Sydney</p>
                                            </div>
                                        </v-card>
									</v-flex>
								</v-layout>
							</v-container>
						</v-carousel-item>
                        <v-carousel-item v-if="$vuetify.breakpoint.xsOnly">
							<v-container grid-list-xl class="px-0 pb-0">
								<v-layout>
									<v-flex xs12>
                                        <v-card flat class="scl-card__card">
                                            <v-img
                                                :src="$root.dummyImage43" class="scl-card__image"
                                                aspect-ratio="1"
                                                max-height="240"
																								max-width="240"

                                            >
                                            </v-img>

                                            <div class="scl-card__content">
                                                <h5 class="scl-card__title">Kate Best</h5>
                                                <p class="scl-card__description">Executive Digital Producer</p>
                                                <p class="scl-card__description">Sydney</p>
                                            </div>
                                        </v-card>
									</v-flex>
								</v-layout>
							</v-container>
						</v-carousel-item>

						<!-- desktop small carousel -->
						<v-carousel-item v-if="$vuetify.breakpoint.smAndUp">
							<v-container grid-list-xl class="px-0 pb-0">
                                <v-layout>
                                    <v-flex sm4>
                                        <v-card flat class="scl-card__card">
                                            <v-img
                                                :src="$root.dummyImage43" class="scl-card__image"
                                                :aspect-ratio="$vuetify.breakpoint.smAndUp? '1': '1'"
                                                max-height="240"
																				max-width="240"

                                            >
                                            </v-img>

                                            <div class="scl-card__content">
                                                <h5 class="scl-card__title">Gav Brownlie</h5>
                                                <p class="scl-card__description">Project Management</p>
                                                <p class="scl-card__description">Sydney</p>
                                            </div>

                                        </v-card>
                                    </v-flex>
                                    <v-flex sm4>
                                        <v-card flat class="scl-card__card">
                                            <v-img
                                                :src="$root.dummyImage43" class="scl-card__image"
                                                :aspect-ratio="$vuetify.breakpoint.smAndUp? '1': '1'"
                                                max-height="240"
																				max-width="240"

                                            >
                                            </v-img>

                                            <div class="scl-card__content">
                                                <h5 class="scl-card__title">Kate Best</h5>
                                                <p class="scl-card__description">Executive Digital Producer</p>
                                                <p class="scl-card__description">Sydney</p>
                                            </div>
                                        </v-card>
                                    </v-flex>
                                    <v-flex sm4>
                                        <v-card flat class="scl-card__card">
                                            <v-img
                                                :src="$root.dummyImage43" class="scl-card__image"
                                                :aspect-ratio="$vuetify.breakpoint.smAndUp? '1': '1'"
                                                max-height="240"
																				max-width="240"

                                            >
                                            </v-img>

                                            <div class="scl-card__content">
                                                <h5 class="scl-card__title">Kate Best</h5>
                                                <p class="scl-card__description">Executive Digital Producer</p>
                                                <p class="scl-card__description">Sydney</p>
                                            </div>
                                        </v-card>
                                    </v-flex>
                                </v-layout>
							</v-container>
						</v-carousel-item>



                        <v-carousel-item v-if="$vuetify.breakpoint.smAndUp">
							<v-container grid-list-xl class="px-0 pb-0">
                                <v-layout>
                                    <v-flex sm4>
                                        <v-card flat class="scl-card__card">
                                            <v-img
                                                :src="$root.dummyImage43" class="scl-card__image"
                                                aspect-ratio="1"
                                                max-height="240"
																				max-width="240"

                                            >
                                            </v-img>

                                            <div class="scl-card__content">
                                                <h5 class="scl-card__title">Gav Brownlie</h5>
                                                <p class="scl-card__description">Project Management</p>
                                                <p class="scl-card__description">Sydney</p>
                                            </div>
                                        </v-card>
                                    </v-flex>
                                    <v-flex sm4>
                                        <v-card flat class="scl-card__card">
                                            <v-img
                                                :src="$root.dummyImage43" class="scl-card__image"
                                                :aspect-ratio="$vuetify.breakpoint.smAndUp? '1': '1'"
                                                max-height="240"
																				max-width="240"

                                            >
                                            </v-img>

                                            <div class="scl-card__content">
                                                <h5 class="scl-card__title">Kate Best</h5>
                                                <p class="scl-card__description">Executive Digital Producer</p>
                                                <p class="scl-card__description">Sydney</p>
                                            </div>
                                        </v-card>
                                    </v-flex>
                                    <v-flex sm4>
                                        <v-card flat class="scl-card__card">
                                            <v-img
                                                :src="$root.dummyImage43" class="scl-card__image"
                                                :aspect-ratio="$vuetify.breakpoint.smAndUp? '1': '1'"
                                                max-height="240"
																				max-width="240"

                                            >
                                            </v-img>

                                            <div class="scl-card__content">
                                                <h5 class="scl-card__title">Deanna Grindrod</h5>
                                                <p class="scl-card__description">UX/UI Design</p>
                                                <p class="scl-card__description">Central Coast</p>
                                            </div>
                                        </v-card>
                                    </v-flex>
                                </v-layout>
							</v-container>
						</v-carousel-item>

					</v-carousel>

				</v-flex>
			</v-layout>
		</v-container>

	</div>
`;

// export template
export default template;
