/**
 * SclSelect
 */

// template
const template = `
	<div>
		<v-select
			attach="scl-select"
			:items="[{ text: 'supercars', value: '/supercars_1' },
					 { text: 'how to use our equipment', value: '/howtouseourequipment' },
					 { text: 'how to better use our equipment', value: '/howtouseourequipment/better' },
					 { text: 'all news', value: '/news' },
					 { text: 'economy', value: '/news/economy' },
					 { text: 'international', value: '/news/international' },
					]"
			class="scl-form__input scl-form__select"
			outline
			:label="label"
			ref="filterSelect"
			@change="filter"
			append-icon="fas fa-chevron-down"
		></v-select>
	</div>
`;

// export template
export default template;
