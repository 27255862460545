/**
 * SclCarouselCustomerStory api
 *
 * cardNewsItems prop includes an array of object including
 * img prop
 * @param {string} "image2.png"
 *
 * title prop
 * @param {string} "title"
 *
 * accent prop
 * @param {string} "#ffa069"
 *
 * copy prop
 * @param {string} "Long text description"
 *
 * newsLink prop
 * @param {string} "www.google.com"
 *
 */

// template
const template = `
        <div class="scl-card-image-text">

            <v-card flat class="scl-card-image-text__card" :href="$root.dummyUrl">
                <v-img
                    :src="$root.dummyImage43" class="scl-card-image-text__image"
                    :aspect-ratio="16/9"
                >
                </v-img>

                <v-card-title primary-title class="scl-card-image-text__content">
									<h5 class="scl-card-image-text__title">{{ $root.dummyWords }} {{ $root.dummyWords }}</h5>

                </v-card-title>
								<div class="scl-card-image-text__description">
								<span v-html="html"></span>
						</div>
                <v-card-actions>
                    <scl-button inline-template :api="primaryBtnApi">
                            <v-btn depressed class="scl-component scl-button scl-button--small scl-button--tertiary scl-card-image-text__link"
                                :outline="propApi.outline"
                                :class="{'scl-button--outline' : propApi.outline}">
                                {{ propApi.copy }} <v-icon class="scl-button__icon">{{ propApi.icon }}</v-icon>
                            </v-btn>
                    </scl-button>
                </v-card-actions>

                <div class="scl-card-image-text__card-hover"></div>
            </v-card>

        </div>
`;

// export template
export default template;
