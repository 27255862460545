/**
 * SclFooter api
 */

// template
const template = `
	<div>
		<v-container class="scl-footer__container py-0">
			<v-layout row wrap>
				<v-flex xs12 sm9 lg6 align-end d-flex>
					<div class="scl-footer__links">
						<v-list class="scl-footer__list">
							<div class="scl-footer__copyright">
								<v-list-tile class="scl-footer__list-item"><span>Copyright &copy; {{ new Date().getFullYear() }} Coates Hire</span></v-list-tile>
							</div>
							<div class="scl-footer__list-container">
								<v-divider v-if="$vuetify.breakpoint.smAndUp" vertical></v-divider>
								<v-list-tile class="scl-footer__list-item" href="#" target="_blank">Terms of Use</v-list-tile>
								<v-divider vertical></v-divider>
								<v-list-tile class="scl-footer__list-item" href="#" target="_blank">Privacy Policy</v-list-tile>
								<v-divider vertical></v-divider>
								<v-list-tile class="scl-footer__list-item" href="#" target="_blank">Sitemap</v-list-tile>
							</div>
						</v-list>
					</div>
				</v-flex>
				<v-flex v-if="$vuetify.breakpoint.mdAndUp" lg3 align-end d-flex text-xs-right pr-5>
					<div>
						<v-icon color="#ff4600" size="24">fab fa-linkedin</v-icon>
						<v-icon color="#ff4600" size="24">fab fa-linkedin</v-icon>
						<v-icon color="#ff4600" size="24">fab fa-linkedin</v-icon>
					</div>
				</v-flex>
			</v-layout>
		</v-container>
	</div>
`;

// export template
export default template;
