/**
 * SclContactForm api
 */

// template
const template = `
	<div>
		<v-card>
			<v-form class="scl-form" ref="form" v-model="valid">
				<v-container grid-list-xl class="scl-contact-form__container pa-0">

					<v-layout row>
						<v-flex lg12>
							<legend class="scl-form__title">ENQUIRY ABOUT OUR TRAINING COURSES</legend>
							<!-- Mobile call -->
							<a v-if="$vuetify.breakpoint.mdAndDown" class="scl-contact-form__call" href="tel:13 15 52">
								<v-icon color="accent">fas fa-phone-alt</v-icon>
								<span>13 15 52</span>
							</a>
						</v-flex>
					</v-layout>

					<v-layout row wrap>
						<!-- form left col -->
						<v-flex :class="{ 'pb-0' : $vuetify.breakpoint.xsOnly }" xs12 sm6 lg4>
							<label class="scl-form__label">field label</label>
							<v-text-field
								v-model="firstName"
								class="scl-form__input"
								:rules="[
									rules.required,
									rules.counter50,
									rules.lettersOnly
								]"
								height="52"
								outline
								autocomplete="given-name"
							></v-text-field>
							<label class="scl-form__label">field label <span>(optional)</span></label>
							<v-text-field
								v-model="lastName"
								class="scl-form__input"
								:rules="[
									rules.required,
									rules.counter50,
									rules.lettersOnly
								]"
								height="52"
								outline
								autocomplete="family-name"
							></v-text-field>
							<label class="scl-form__label">field label</label>
							<v-text-field
								v-model="email"
								class="scl-form__input"
								required
								:rules="[rules.required, rules.email]"
								outline
								height="52"
								autocomplete="email"
							></v-text-field>
							<label class="scl-form__label">field label</label>
							<v-text-field
								v-model="phone"
								class="scl-form__input"
								:rules="[
									rules.required,
									rules.australianPhoneNumber
								]"
								height="52"
								outline
								autocomplete="tel"
							></v-text-field>
							<label class="scl-form__label">field label</label>
							<v-text-field
								v-model="company"
								class="scl-form__input"
								outline
								height="52"
							></v-text-field>
						</v-flex>

						<!-- form right col -->
						<v-flex :class="{ 'pt-0' : $vuetify.breakpoint.xsOnly }" xs12 sm6 lg4>
							<label class="scl-form__label">field label</label>
							<v-autocomplete
								v-model="location"
								:items="locationItems"
								:search-input.sync="search"
								outline
								class="scl-form__input scl-form__input--location"
								height="52"
								hide-no-data
								placeholder="enter postcode or suburb"
								item-text="Description"
								item-value="API"
								return-object
								clearable
								auto-select-first
								:loading="isLoading"
								cache-items
								:rules="[
									rules.required
								]"
							>
							</v-autocomplete>
							<label class="scl-form__label">field label</label>
							<v-select
								v-model="subject"
								:items="items"
								class="scl-form__input scl-form__select"
								height="52"
								outline
								append-icon="fas fa-chevron-down"
							></v-select>
							<label class="scl-form__label">field label</label>
							<v-textarea
									v-model="enquiry"
									class="scl-form__input scl-form__textarea"
									:rules="[
										rules.required,
										rules.counter500
									]"
									outline
									name="enquiry"
									type="text"
									height="165"
								></v-textarea>
							<label class="scl-form__label">field label</label>
							<scl-file-upload class="scl-component scl-file-upload pb-3"></scl-file-upload>
							<label class="scl-form__label">&nbsp;</label>
							<v-btn block class="scl-form__btn" color="accent" depressed @click="submitForm">Submit Enquiry</v-btn>
							<p class="scl-form__terms">By submitting this enquiry you agree to Coates Hire's Communication <a href="#">Terms &amp; Conditions</a>.</p>
						</v-flex>

						<!-- right panel -->
						<v-flex v-if="$vuetify.breakpoint.lgAndUp" lg4>
							<h6 class="scl-form__label">GIVE US A CALL</h6>
							<a class="scl-contact-form__call" href="tel:1300 657 867">
								<v-icon color="accent">fas fa-phone-alt</v-icon>
								<span>1300 657 867</span>
							</a>
							<v-list dense class="scl-contact-form__list">
								<v-list-tile>
									<v-list-tile-action>
										<v-icon>fas fa-check</v-icon>
									</v-list-tile-action>
									<v-list-tile-content>
										<v-list-tile-title>Safety Standard Gurantee</v-list-tile-title>
									</v-list-tile-content>
								</v-list-tile>
								<v-list-tile>
									<v-list-tile-action>
										<v-icon>fas fa-check</v-icon>
									</v-list-tile-action>
									<v-list-tile-content>
										<v-list-tile-title>24/7 Online Services</v-list-tile-title>
									</v-list-tile-content>
								</v-list-tile>
								<v-list-tile>
									<v-list-tile-action>
										<v-icon>fas fa-check</v-icon>
									</v-list-tile-action>
									<v-list-tile-content>
										<v-list-tile-title>Same day delivery available</v-list-tile-title>
									</v-list-tile-content>
								</v-list-tile>
							</v-list>
						</v-flex>
					</v-layout>
				</v-container>
			</v-form>
		</v-card>

		<v-dialog
		v-model="dialog"
		max-width="960px"
		:persistent="false"
		content-class="scl-component scl-enquiry-modal"
		>
			<v-card>
				<v-icon class="scl-enquiry-modal__close" large @click="dialog = false">far fa-times-circle</v-icon>
				<v-container grid-list-xl class="scl-contact-form__container">
					<v-layout row>
						<v-flex lg12>
							<legend class="scl-form__title">Your enquiry has been submitted</legend>
							<h4>Thank you for your enquiry. One of our team members will get back to you as soon as possible.</h4>
						</v-flex>
					</v-layout>
				</v-container>
			</v-card>
		</v-dialog>
	</div>
`;

// export template
export default template;
