/**
 * SclCard api
 *
 * displayAccordion prop
 * @param {boolean} true | false
 *
 * variant prop
 * @param {boolean} true | false
 *
 */

// template
const template = `
	<div>
		<!-- testimonial card variant -->
		<div v-if="propApi.variant" class="scl-card__variant--card">
			<p class="scl-card__variant--datetime">{{ $root.dummyDate }}</p>
			<p class="scl-card__variant--testimonial-text">&ldquo;{{ $root.dummyWords }}&rdquo;</p>
			<p class="scl-card__variant--testimonial-author">{{ $root.dummyWord }}</p>
		</div>


		<v-card v-else class="scl-card__card">
			<a href="#">
				<v-img class="scl-card__img" src="https://www.coateshire.com.au/-/media/kentico-media-libraries/images/equipment/product/55-Air-Operated-Breaker-Large_1.jpg?width=500&height=500&ext=.jpg" aspect-ratio="1" contain></v-img>
			</a>
			<v-card-title class="scl-card__title-container">
				<p v-if="propApi.description">Scissor Lift - Electric - 5.6M (18.5ft)</p>
				<a class="scl-card__title-anchor" href="#">
					<h4 class="scl-card__title" :class="{ 'with-description' : propApi.description }">hire now</h4>
				</a>
			</v-card-title>
			<v-card-actions v-if="propApi.displayAccordion" class="scl-card__actions">
				<v-expansion-panel v-model="panel" class="scl-card__accordion">
					<v-expansion-panel-content
					:hide-actions="true"
					class="scl-card__accordion-container">
						<template v-slot:header>
							{{ panelContentText }}
						</template>
						<v-card class="scl-card__accordion-item">
							<v-card-text class="scl-card__accordion-item-container">
								<a class="scl-card__accordion-anchor" href="#"><scl-dummy-data :content="$root.dummyWords"></scl-dummy-data></a>
							</v-card-text>
						</v-card>
						<v-card class="scl-card__accordion-item">
							<v-card-text class="scl-card__accordion-item-container">
								<a class="scl-card__accordion-anchor" href="#"><scl-dummy-data :content="$root.dummyWords"></scl-dummy-data></a>
							</v-card-text>
						</v-card>
					</v-expansion-panel-content>
				</v-expansion-panel>
			</v-card-actions>
		</v-card>
	</div>
`;

// export template
export default template;
