/**
 * SclProductDetail
 */

// template
const template = `
	<div>
		<!-- mobile accordions -->
		<template v-if="$vuetify.breakpoint.smAndDown">
			<v-expansion-panel>
				<v-expansion-panel-content>
					<template v-slot:header>
						<div class="scl-product-detail__title">accordion 1</div>
					</template>
					<v-card>
						<v-card-text>
							<scl-product-subtype class="scl-component scl-product-subtype" ></scl-product-subtype>
						</v-card-text>
					</v-card>
				</v-expansion-panel-content>
				<v-expansion-panel-content>
					<template v-slot:header>
						<div class="scl-product-detail__title">accordion 2</div>
					</template>
					<v-card>
						<v-card-text>accordion 2 content</v-card-text>
					</v-card>
				</v-expansion-panel-content>
				<v-expansion-panel-content>
					<template v-slot:header>
						<div class="scl-product-detail__title">accordion 3</div>
					</template>
					<v-card>
						<v-card-text>accordion 3 content</v-card-text>
					</v-card>
				</v-expansion-panel-content>
				<v-expansion-panel-content>
					<template v-slot:header>
						<div class="scl-product-detail__title">accordion 4</div>
					</template>
					<v-card>
						<v-card-text>accordion 4 content</v-card-text>
					</v-card>
				</v-expansion-panel-content>
			</v-expansion-panel>
			<v-container>
				<v-flex xs12 class="small-detail">
					<h4>Disclaimer</h4>
					All images and product specifications have been reproduced for your convenience to assist you with product enquiries only. 
					Coates Hire cannot guarantee that the information is free from error, omissions, or is exhaustive. Coates Hire makes no 
					representation that any of the products are suitable for your purposes, and you acknowledge that you have relied on your 
					own skill and judgement in this regard. Coates Hire will not be held liable for any claim, loss or damage arising from the use of,
					or reliance on, the information on this website. Please contact a Coates Hire representative for detailed product information.
				</v-flex>
			</v-container>
		</template>
		<!-- desktop tabs -->
		<template v-else>
			<!-- tabs -->
			<v-tabs
				v-model="activeTab"
			>
				<v-container class="py-0 concrete-bg">
					<v-layout row>
						<v-flex xs12>
							<div class="scl-product-detail__tabs">
								<v-tab class="scl-product-detail__tab" href="#tab-1" :ripple="false">
									<span>tab 1 this is a test</span>
								</v-tab>
								<v-tab class="scl-product-detail__tab" href="#tab-2" :ripple="false">
									<span>tab 2</span>
								</v-tab>
								<v-tab class="scl-product-detail__tab" href="#tab-3" :ripple="false">
									<span>tab 3</span>
								</v-tab>
								<v-tab class="scl-product-detail__tab" href="#tab-4" :ripple="false">
									<span>tab 4</span>
								</v-tab>
							</div>
						</v-flex>
					</v-layout>
				</v-container>
			</v-tabs>
			<div class="scl-product-detail__section">
				<v-container class="py-0">
					<v-layout row wrap>
						<v-flex xs12>
							<!-- tabs content -->
							<v-tabs-items v-model="activeTab">
								<!-- content -->
								<v-tab-item value="tab-1" class="scl-product-detail__tab-content">
									<scl-product-subtype class="scl-component scl-product-subtype" ></scl-product-subtype>
								</v-tab-item>
								<!-- content -->
								<v-tab-item value="tab-2" class="scl-product-detail__tab-content">
									tab 2 content
								</v-tab-item>
								<!-- content -->
								<v-tab-item value="tab-3" class="scl-product-detail__tab-content">
									tab 3 content
								</v-tab-item>
								<!-- content -->
								<v-tab-item value="tab-4" class="scl-product-detail__tab-content">
									tab 4 content
								</v-tab-item>
							</v-tabs-items>
						</v-flex>
						<v-flex xs12 class="small-detail">
							<h4>Disclaimer</h4>
							All images and product specifications have been reproduced for your convenience to assist you with product enquiries only. 
							Coates Hire cannot guarantee that the information is free from error, omissions, or is exhaustive. Coates Hire makes no 
							representation that any of the products are suitable for your purposes, and you acknowledge that you have relied on your 
							own skill and judgement in this regard. Coates Hire will not be held liable for any claim, loss or damage arising from the use of,
							or reliance on, the information on this website. Please contact a Coates Hire representative for detailed product information.
						</v-flex>
					</v-layout>
				</v-container>
			</div>
		</template>

	</div>
`;

// export template
export default template;
