/**
 * SclBranchFind
 */

const template = `
	<div>
		<v-container>
			<v-layout row wrap>
				<v-flex xs12>
					<div v-if="hasErrored">
						<v-alert
							:value="true"
							type="error"
						>
							Something has gone wrong, no branch data available please try again later.
						</v-alert>
					</div>
					<div v-else>
						<h2 class="scl-branch-find__title">find branch by state</h2>
						<!-- ACT -->
						<v-expansion-panel class="scl-branch-find__accordion" v-model="panel[0]" @input="panelUpdate(0)">
							<v-expansion-panel-content class="scl-branch-find__accordion-container">
								<template v-slot:header>
									<h3 class="scl-branch-find__state">Australian Capital Territory <span>({{ getBranchTotal(ACT) }})</span></h3>
								</template>
								<v-card v-for="(region, name, index) in ACT" :key="index" class="scl-branch-find__region-container">
									<h4 class="scl-branch-find__region">{{ name }}</h4>
									<ul class="scl-branch-find__list">
										<li v-for="branch in region" class="scl-branch-find__list-item">
											<a :href="branch.StorePageUrl">{{ branch.StoreName }}</a>
										</li>
									</ul>
								</v-card>
							</v-expansion-panel-content>
						</v-expansion-panel>

						<!-- NSW -->
						<v-expansion-panel class="scl-branch-find__accordion" v-model="panel[1]" @input="panelUpdate(1)">
							<v-expansion-panel-content class="scl-branch-find__accordion-container">
								<template v-slot:header>
									<h3 class="scl-branch-find__state">New South Wales <span>({{ getBranchTotal(NSW) }})</span></h3>
								</template>
								<v-card v-for="(region, name, index) in NSW" :key="index" class="scl-branch-find__region-container">
									<h4 class="scl-branch-find__region">{{ name }}</h4>
									<ul class="scl-branch-find__list">
										<li v-for="branch in region" class="scl-branch-find__list-item">
											<a :href="branch.StorePageUrl">{{ branch.StoreName }}</a>
										</li>
									</ul>
								</v-card>
							</v-expansion-panel-content>
						</v-expansion-panel>

						<!-- NT -->
						<v-expansion-panel class="scl-branch-find__accordion" v-model="panel[2]" @input="panelUpdate(2)">
							<v-expansion-panel-content class="scl-branch-find__accordion-container">
								<template v-slot:header>
									<h3 class="scl-branch-find__state">Northern Territory <span>({{ getBranchTotal(NT) }})</span></h3>
								</template>
								<v-card v-for="(region, name, index) in NT" :key="index" class="scl-branch-find__region-container">
									<h4 class="scl-branch-find__region">{{ name }}</h4>
									<ul class="scl-branch-find__list">
										<li v-for="branch in region" class="scl-branch-find__list-item">
											<a :href="branch.StorePageUrl">{{ branch.StoreName }}</a>
										</li>
									</ul>
								</v-card>
							</v-expansion-panel-content>
						</v-expansion-panel>

						<!-- QLD -->
						<v-expansion-panel class="scl-branch-find__accordion" v-model="panel[3]" @input="panelUpdate(3)">
							<v-expansion-panel-content class="scl-branch-find__accordion-container">
								<template v-slot:header>
									<h3 class="scl-branch-find__state">Queensland <span>({{ getBranchTotal(QLD) }})</span></h3>
								</template>
								<v-card v-for="(region, name, index) in QLD" :key="index" class="scl-branch-find__region-container">
									<h4 class="scl-branch-find__region">{{ name }}</h4>
									<ul class="scl-branch-find__list">
										<li v-for="branch in region" class="scl-branch-find__list-item">
											<a :href="branch.StorePageUrl">{{ branch.StoreName }}</a>
										</li>
									</ul>
								</v-card>
							</v-expansion-panel-content>
						</v-expansion-panel>

						<!-- SA -->
						<v-expansion-panel class="scl-branch-find__accordion" v-model="panel[4]" @input="panelUpdate(4)">
							<v-expansion-panel-content class="scl-branch-find__accordion-container">
								<template v-slot:header>
									<h3 class="scl-branch-find__state">South Australia <span>({{ getBranchTotal(SA) }})</span></h3>
								</template>
								<v-card v-for="(region, name, index) in SA" :key="index" class="scl-branch-find__region-container">
									<h4 class="scl-branch-find__region">{{ name }}</h4>
									<ul class="scl-branch-find__list">
										<li v-for="branch in region" class="scl-branch-find__list-item">
											<a :href="branch.StorePageUrl">{{ branch.StoreName }}</a>
										</li>
									</ul>
								</v-card>
							</v-expansion-panel-content>
						</v-expansion-panel>

						<!-- TAS -->
						<v-expansion-panel class="scl-branch-find__accordion" v-model="panel[5]" @input="panelUpdate(5)">
							<v-expansion-panel-content class="scl-branch-find__accordion-container">
								<template v-slot:header>
									<h3 class="scl-branch-find__state">Tasmania <span>({{ getBranchTotal(TAS) }})</span></h3>
								</template>
								<v-card v-for="(region, name, index) in TAS" :key="index" class="scl-branch-find__region-container">
									<h4 class="scl-branch-find__region">{{ name }}</h4>
									<ul class="scl-branch-find__list">
										<li v-for="branch in region" class="scl-branch-find__list-item">
											<a :href="branch.StorePageUrl">{{ branch.StoreName }}</a>
										</li>
									</ul>
								</v-card>
							</v-expansion-panel-content>
						</v-expansion-panel>

						<!-- VIC -->
						<v-expansion-panel class="scl-branch-find__accordion" v-model="panel[6]" @input="panelUpdate(6)">
							<v-expansion-panel-content class="scl-branch-find__accordion-container">
								<template v-slot:header>
									<h3 class="scl-branch-find__state">Victoria <span>({{ getBranchTotal(VIC) }})</span></h3>
								</template>
								<v-card v-for="(region, name, index) in VIC" :key="index" class="scl-branch-find__region-container">
									<h4 class="scl-branch-find__region">{{ name }}</h4>
									<ul class="scl-branch-find__list">
										<li v-for="branch in region" class="scl-branch-find__list-item">
											<a :href="branch.StorePageUrl">{{ branch.StoreName }}</a>
										</li>
									</ul>
								</v-card>
							</v-expansion-panel-content>
						</v-expansion-panel>

						<!-- WA -->
						<v-expansion-panel class="scl-branch-find__accordion" v-model="panel[7]" @input="panelUpdate(7)">
							<v-expansion-panel-content class="scl-branch-find__accordion-container">
								<template v-slot:header>
									<h3 class="scl-branch-find__state">Western Australia <span>({{ getBranchTotal(WA) }})</span></h3>
								</template>
								<v-card v-for="(region, name, index) in WA" :key="index" class="scl-branch-find__region-container">
									<h4 class="scl-branch-find__region">{{ name }}</h4>
									<ul class="scl-branch-find__list">
										<li v-for="branch in region" class="scl-branch-find__list-item">
											<a :href="branch.StorePageUrl">{{ branch.StoreName }}</a>
										</li>
									</ul>
								</v-card>
							</v-expansion-panel-content>
						</v-expansion-panel>

					</div>
				</v-flex>
			</v-layout>
		</v-container>
	</div>
`;

export default template;
