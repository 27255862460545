/**
 * Header Small
 */

const template = /* html */ `
<div>
<div ref="header">
<scl-alert :api="{dismissible: true}" id="{12345}" class="scl-alert"></scl-alert>

	<v-container class="scl-header-small__header align-center d-flex">
		<v-layout row wrap>
			<v-flex xs6 d-flex align-center>
				<a href="#">
					<v-img
						src="/assets/img/logo-coates.svg"
						height="30"
						width="110"
						contain
					></v-img>
				</a>
			</v-flex>
			<v-flex class="text-xs-right">
				<v-menu
					attach=".scl-header-small__toolbar"
					:close-on-content-click="false"
					content-class="scl-header-small__menu"
				>
					<template v-slot:activator="scope">
						<a href="#"
							v-on="scope.on"
						>
							<v-icon color="#ffffff">fas fa-search</v-icon>
						</a>
					</template>
					<v-container>
						<v-layout class="scl-header-small__menu-layout">
							<v-flex>
								<v-list class="scl-header-small__menu-list py-2">
									<v-list-tile>
										<v-autocomplete
											v-model="model"
											:items="items"
											:search-input.sync="search"
											:filter="customFilter"
											color="primary"
											class="scl-header-small__input scl-form__input py-0"
											:menu-props="{contentClass:'scl-header-large__autocomplete'}"
											height="64"
											hide-no-data
											hide-details
											item-text="Name"
											item-value="URL"
											clear-icon="far fa-times"
											prepend-inner-icon="fas fa-search"
											return-object
											clearable
											ref="autocompleteField"
											auto-select-first
											:loading="isLoading"
											cache-items
											solo
											placeholder="Try 'Scissor Lift'"
										></v-autocomplete>
									</v-list-tile>
								</v-list>
							</v-flex>
						</v-layout>
					</v-container>
				</v-menu>
				<v-divider vertical class="mx-3"></v-divider>
				<a href="#" @click.stop="mobileMenu = !mobileMenu"><v-icon color="#ffffff">fas fa-bars</v-icon></a>
			</v-flex>
		</v-layout>
	</v-container>

	<v-navigation-drawer
		v-model="mobileMenu" class="scl-header-small__drawer" absolute temporary hide-overlay v-bind:style="[{'margin-top' : setMargin}, {'max-height' : setHeight }]">
		<v-expansion-panel>
			<v-expansion-panel-content>
				<template v-slot:actions>
					<v-icon color="accent">$vuetify.icons.expand</v-icon>
				</template>
				<template v-slot:header>
					<div>Equipment Hire</div>
				</template>
				<v-list class="scl-header-small__list">
					<v-list-tile>
						<a href="/contact-us" target="_top">Contact us</a>
					</v-list-tile>
					<v-list-tile>
						<a href="/about-us" target="_top">Our Story</a>
					</v-list-tile>
					<v-list-tile>
						<a href="/contact-us" target="_top">Media</a>
					</v-list-tile>
				</v-list>
			</v-expansion-panel-content>
			<v-expansion-panel-content>
				<template v-slot:actions>
					<v-icon color="accent">$vuetify.icons.expand</v-icon>
				</template>
				<template v-slot:header>
					<div>Engineering Solutions</div>
				</template>
				<v-list class="scl-header-small__list">
					<v-list-tile>
						<a href="/contact-us" target="_top">Contact us</a>
					</v-list-tile>
					<v-list-tile>
						<a href="/about-us" target="_top">Our Story</a>
					</v-list-tile>
					<v-list-tile>
						<a href="/contact-us" target="_top">Media</a>
					</v-list-tile>
				</v-list>
			</v-expansion-panel-content>
		</v-expansion-panel>
		<v-divider  class="my-3"></v-divider>
		<v-list class="scl-header-small__list">
			<v-list-tile>
				<v-list-tile-content>
					<a href="https://hire-now.coateshire.com.au/" target="_blank" >
						<v-icon size="16" color="#FF4600" class="pr-3">far fa-shopping-cart</v-icon>
						Hire Now
					</a>
				</v-list-tile-content>
			</v-list-tile>
			<v-list-tile>
				<v-list-tile-content>
					<a href="https://my.coateshire.com.au/" target="_blank" >
						<v-icon size="16" color="#FF4600" class="pr-3">far fa-user</v-icon>
						My Coates Hire
					</a>
				</v-list-tile-content>
			</v-list-tile>
			<v-list-tile>
				<v-list-tile-content>
					<a href="/find-a-branch" target="_blank" >
						<v-icon size="16" color="#FF4600" class="pr-3">far fa-map-marker-alt</v-icon>
						Find a branch
					</a>
				</v-list-tile-content>
			</v-list-tile>
			<v-list-tile>
				<v-list-tile-content>
					<a href="tel:13 15 52" target="_blank" >
						<v-icon size="16" color="#FF4600" class="pr-3">far fa-phone</v-icon>
						13 15 52
					</a>
				</v-list-tile-content>
			</v-list-tile>
		</v-list>
		<v-divider class="my-3"></v-divider>
		<v-list class="scl-header-small__list">
			<v-list-tile>
				<a href="/contact-us" target="_top" >Contact us</a>
			</v-list-tile>
			<v-list-tile>
				<a href="/about-us" target="_top" >Our Story</a>
			</v-list-tile>
			<v-list-tile>
				<a href="/contact-us" target="_top" >Media</a>
			</v-list-tile>
			<v-list-tile>
				<div class="scl-header-small__social">
					<a href="https://www.facebook.com/CoatesHire/" target="_blank" class="pr-1"><v-icon size="24" >fab fa-facebook</v-icon></a>
					<a href="https://www.instagram.com/coateshire/" target="_blank" class="pr-1"><v-icon size="24" >fab fa-instagram</v-icon></a>
					<a href="https://www.facebook.com/CoatesHire/" target="_blank" class="pr-1"><v-icon size="24" >fab fa-linkedin</v-icon></a>
				</div>
			</v-list-tile>
		</v-list>
	</v-navigation-drawer>
	</div>
</div>
`;

export default template;
