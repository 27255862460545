/**
 * SclBranchDEtail
 */

const template = `
	<div>
		<v-container grid-list-xl>
			<v-layout row wrap>
				<v-flex xs12 md5 lg4>

					<div class="scl-branch-detail__sidebar-item">
						<h6 class="scl-branch-detail__branch-title">General Hire Branch</h6>
						<ul class="scl-branch-detail__branch-list">
							<li>340 Botany Rd</li>
							<li>Alexandria 2015 Sydney NSW</li>
							<li><v-icon>fas fa-map-marker-alt</v-icon> <a href="#" target="_blank">directions</a></li>
							<li class="scl-branch-detail__branch-phone"><v-icon>fas fa-phone-alt</v-icon> (02) 9582 7920</li>
							<li><v-icon>fas fa-envelope</v-icon> <a href="mailto:#">alexandria.branch@coateshire.com.au</a></li>
						</ul>
					</div>
					<div class="scl-branch-detail__sidebar-item">
						<h6 class="scl-branch-detail__branch-title">opening hours</h6>
						<ul class="scl-branch-detail__branch-list">
							<!-- Loop this with data source from Sitecore -->
							<li class="d-flex">
								<span>Monday</span>
								<span class="text-xs-right">7.00 AM-5.00 PM</span>
							</li>
							<li class="d-flex">
								<span>Tuesday</span>
								<span class="text-xs-right">7.00 AM-5.00 PM</span>
							</li>
							<li class="d-flex">
								<span>Wednesday</span>
								<span class="text-xs-right">7.00 AM-5.00 PM</span>
							</li>
						</ul>
					</div>

				</v-flex>

				<v-flex xs12 md7 lg8>
					<scl-branch-map />
					<div class="scl-branch-detail__map-summary">
						Your local Thornton Coates Hire branch in Hunter / Central & North Coast will provide you the best equipment hire service in Beresfield. We've got everything covered, be it tools for a DIY job, gear for on the worksite in Beresfield or speciality equipment for mining and infrastructure projects across Beresfield, Hunter / Central & North Coast, NSW.
					</div>
					<div class="scl-branch-detail__services">
						<h5 class="scl-branch-detail__services-title">available services</h5>
						<ul class="scl-branch-detail__services-list">
							<li>Construction Lasers</li>
							<li>Generators & Power Distribution</li>
							<li>Ladders & Scaffolding</li>
							<li>Air & Air Accessories</li>
							<li>Confined Space Entry</li>
							<li>Floor & Cleaning</li>
						</ul>
					</div>
				</v-flex>

			</v-layout>
		</v-container>
	</div>
`;

export default template;
