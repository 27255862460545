/**
 * SclCarouselTestimonial api
 *
 * height class
 * scl-carousel-testimonial--fixedheight (uses the height prop)
 * scl-carousel-testimonial--autoheight (ignores the height prop auto scales)
 *
 * cycle prop
 * @param {boolean} true || false
 *
 * delimiterIcon prop
 * @param {string} string "fas fa-skull"
 *
 * hideDelimiters prop
 * @param {boolean} true || false
 *
 * hideControls prop
 * @param {boolean} true || false
 *
 * height prop
 * @param {number} int 400 (small)
 * @param {number} int 600 (medium)
 * @param {number} int 700 (large)
 *
 * interval prop
 * @param {number} int 7000 (slow)
 * @param {number} int 5000 (medium)
 * @param {number} int 3000 (fast)
 *
 * nextIcon prop
 * @param {string} string "fas fa-chevron-circle-right"
 *
 * prevIcon prop
 * @param {string} string "fas fa-chevron-circle-right"
 */

const template = `
<scl-carousel-testimonial
		inline-template
		class="scl-component scl-carousel-testimonial"
		:api="{
			cycle: true,
			delimiterIcon: $root.dummyIcon,
			hideDelimiters: false,
			hideControls: false,
			height: 768,
			interval: 3000,
			nextIcon: $root.dummyNextIcon,
			prevIcon: $root.dummyPrevIcon
		}"
		>
	<v-carousel
		:cycle="propApi.cycle"
		:delimiter-icon="propApi.delimiterIcon"
		:height="propApi.height"
		:hide-controls="propApi.hideControls"
		:hide-delimiters="propApi.hideDelimiters"
		:interval="propApi.interval"
		:next-icon="propApi.nextIcon"
		:prev-icon="propApi.prevIcon"
	>
		<v-carousel-item>
			<v-container fluid grid-list-md>
				<v-layout :column="$vuetify.breakpoint.mdAndDown">
					<v-flex>
						<v-card >
							<scl-card-image
								:api="{
									href: 'https://www.coateshire.com.au/universal-telemetry-solution',
									title: 'Multiplex',
									img: 'https://picsum.photos/1032/768',
									bgColour: '#587735',
									variant: true,
									subtitle: 'When building the new Maitland Hospital, Multiplex needed bespoke temporary site facilities for 65 staff and 600 sub-contractors. Find out how we delivered on their vision. '
								}"
							>
							</scl-card-image>
						</v-card>
					</v-flex>
				</v-layout>
	</v-container>
		</v-carousel-item>
	</v-carousel>
</scl-carousel-testimonial>
`;

export default template;
